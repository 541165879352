@font-face {
  font-family: 'Poppins-Light';
  src: url('/assets/fonts/poppins/Poppins-Light.eot');
  src: url('/assets/fonts/poppins/Poppins-Light.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/poppins/Poppins-Light.svg#Poppins-Light') format('svg'),
    url('/assets/fonts/poppins/Poppins-Light.ttf') format('truetype'),
    url('/assets/fonts/poppins/Poppins-Light.woff') format('woff'),
    url('/assets/fonts/poppins/Poppins-Light.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins-Regular';
  src: url('/assets/fonts/poppins/Poppins-Regular.eot');
  src: url('/assets/fonts/poppins/Poppins-Regular.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/poppins/Poppins-Regular.svg#Poppins-Regular') format('svg'),
    url('/assets/fonts/poppins/Poppins-Regular.ttf') format('truetype'),
    url('/assets/fonts/poppins/Poppins-Regular.woff') format('woff'),
    url('/assets/fonts/poppins/Poppins-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins-Medium';
  src: url('/assets/fonts/poppins/Poppins-Medium.eot');
  src: url('/assets/fonts/poppins/Poppins-Medium.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/poppins/Poppins-Medium.svg#Poppins-Medium') format('svg'),
    url('/assets/fonts/poppins/Poppins-Medium.ttf') format('truetype'),
    url('/assets/fonts/poppins/Poppins-Medium.woff') format('woff'),
    url('/assets/fonts/poppins/Poppins-Medium.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins-SemiBold';
  src: url('/assets/fonts/poppins/Poppins-SemiBold.eot');
  src: url('/assets/fonts/poppins/Poppins-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/poppins/Poppins-SemiBold.svg#Poppins-SemiBold') format('svg'),
    url('/assets/fonts/poppins/Poppins-SemiBold.ttf') format('truetype'),
    url('/assets/fonts/poppins/Poppins-SemiBold.woff') format('woff'),
    url('/assets/fonts/poppins/Poppins-SemiBold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins-Bold';
  src: url('/assets/fonts/poppins/Poppins-Bold.eot');
  src: url('/assets/fonts/poppins/Poppins-Bold.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/poppins/Poppins-Bold.svg#Poppins-Bold') format('svg'),
    url('/assets/fonts/poppins/Poppins-Bold.ttf') format('truetype'),
    url('/assets/fonts/poppins/Poppins-Bold.woff') format('woff'),
    url('/assets/fonts/poppins/Poppins-Bold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins-ExtraBold';
  src: url('/assets/fonts/poppins/Poppins-ExtraBold.eot');
  src: url('/assets/fonts/poppins/Poppins-ExtraBold.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/poppins/Poppins-ExtraBold.svg#Poppins-ExtraBold') format('svg'),
    url('/assets/fonts/poppins/Poppins-ExtraBold.ttf') format('truetype'),
    url('/assets/fonts/poppins/Poppins-ExtraBold.woff') format('woff'),
    url('/assets/fonts/poppins/Poppins-ExtraBold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'LeagueGothic-Regular';
  src: url('/assets/fonts/leaguegothi/LeagueGothic-Regular.eot');
  src: url('/assets/fonts/leaguegothi/LeagueGothic-Regular.eot?#iefix') format('embedded-opentype'),
       url('/assets/fonts/leaguegothi/LeagueGothic-Regular.svg#LeagueGothic-Regular') format('svg'),
       url('/assets/fonts/leaguegothi/LeagueGothic-Regular.ttf') format('truetype'),
       url('/assets/fonts/leaguegothi/LeagueGothic-Regular.woff') format('woff'),
       url('/assets/fonts/leaguegothi/LeagueGothic-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
// ======================================================================================================= //
// ======================================================================================================= //

html,
body {
  font-family: 'Poppins-Regular';
}

// loading -----------
.loading {
  animation: loading 1s ease-in-out alternate infinite;
}

.rect {
  height: 0.8vw;
  width: 95%;
  background-color: #eee;
  margin-bottom: 0.5vw;
  border-radius: 0.5vw;
  min-width: 5vw;
}

.semi-rect {
  height: 0.8vw;
  width: 50%;
  background-color: #eee;
  margin-bottom: 0.5vw;
  border-radius: 0.5vw;
  min-width: 5vw;
}

.small-rect {
  height: 0.8vw;
  width: 25%;
  background-color: #eee;
  margin-bottom: 0.5vw;
  border-radius: 0.5vw;
  min-width: 5vw;
}

// loading -----------

.dimmed {
  opacity: 0.5;
  filter: blur(8px);
}

.extraToolTip {
  white-space: pre-line;
}

.font-regular {
  font-family: "Poppins-Regular";
}

.font-medium {
  font-family: "Poppins-Medium";
}

.font-semi-bold {
  font-family: "Poppins-SemiBold";
}

.font-bold {
  font-family: "Poppins-Bold";
}

.noDisplay {
  display: none;
}

.noBorder {
  border: none !important;
}

.pl-vw {
  padding-left: 2.8vw; // 48
}

.mb-vw {
  margin-bottom: 1vw; // 16
}

.mr-vw {
  margin-right: 1vw !important; // 16
}

.pr-49 {
  padding-right: 3vw; // 49
}

.padder {
  // padding: 5vw 3.5vw 3vw 7vw;
  padding: 16vw 3.5vw 3vw 7vw;
  overflow: hidden;
}

.heading-3 {
  font-size: 1.4vw; // 28px
}

.active-style {
  // padding: 12px 20px;
  padding: 0.6vw 1vw;
  border: 1px solid #D6D6D6;
  border-radius: 8px;

  .heading {
    font-family: 'Poppins-Regular';
    font-size: 1.4vw; // 28px
    margin: 0;
  }

  .num {
    line-height: 1;
    font-size: 3.4vw; // 76
    color: rgba(30, 45, 91, 1);
    font-family: 'LeagueGothic-Regular';
    text-shadow: 0px 3px 6px #00000029;
  }

  img {
    width: 3.2vw;
  }
}

.active-splitter {
  margin: 1vw 0; // 28 0
  border-top: 1px solid #DDDDDD;
}

.challenges {
  display: flex;

  .challenge {
    position: absolute;

    img {
      margin-right: 0.9vw; // 12
      height: 2.25vw; //40
    }

    .square {
      margin-right: 0.9vw; // 12
      height: 2.25vw; //40
      width: 2.25vw; //40
      background-color: #eee;
    }

    span {
      position: absolute;
      left: -0.5vw;
      top: 0;
      background: rgba(30, 45, 91, 1);
      color: #fff;
      width: 1vw; // 15
      height: 1vw; // 15
      border-radius: 1vw; // 15
      font-size: 0.7vw; // 10
      font-family: 'Poppins-SemiBold';

      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 4;
    }
  }

  .extra {
    width: 2.25vw; //40
    height: 2.25vw; //40

    background: #EBEBEB;
    border-radius: 2.25vw; //40

    text-align: center;
    font-size: 1.05vw; // 16px
    font-weight: 600px;
    font-family: 'Poppins-SemiBold';
    letter-spacing: 0px;
    color: #000000;

    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.card.style-1 {
  display: flex;
  align-items: center;
  flex-flow: row;
  // padding: .4rem 1rem;
  padding: 0.6vw 1vw;
  border-radius: 0.8vw; // 10px
  border: 0;

  margin-bottom: 0.6vw; // 24

  background: transparent linear-gradient(266deg, #646464 0%, #9B9B9B 100%) 0% 0% no-repeat padding-box;
}

.card.style-1 .name {
  flex: 1;
  line-height: 1;
  color: #ffffff;
  font-family: "LeagueGothic-Regular";
  display: flex;
  align-items: center;
  text-transform: uppercase;
}

.name-size-1,
.name-size-2,
.name-size-3 {
  font-family: 'LeagueGothic-Regular';
}

.name-size-1 {
  font-size: 2.2vw; // 48px
}

.name-size-2 {
  font-size: 2vw; // 42px;
}

.name-size-3 {
  font-size: 1.4vw; // 28px
}

.card.style-1 .name .info {
  width: 100%;
}

.card.style-1 .info .desc {
  display: block;
  // margin-top: -14px;
  // color: #dfdfdf;
  // -----------------------

  color: #FFFFFF;
  text-shadow: 0px 3px 6px #00000029;

  font-size: 1.02vw; // 22px
  font-family: 'Poppins-Regular';
  text-transform: uppercase;
}

.info {
  display: flex;
  flex-direction: column;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.bg-2 {
  .info {
    display: flex;
    flex-direction: column;
    
    .desc {
      font-size: 0.9vw !important; // 18px
    }
  }
}

.card.style-1 .score {
  color: #ffffff;
  font-family: "LeagueGothic-Regular";
}

.score-size-1 {
  font-size: 2.2vw; // 48px
}

.score-size-2 {
  font-size: 1.4vw; // 28
}

.card.style-1 img {
  border-radius: 50%;
  border: .1rem solid #ffffff;
  margin-right: .5rem;
}

.img-size-1 {
  width: 3.5rem;
  height: 3.5rem;
}

.img-size-2 {
  width: 3rem;
  height: 3rem;
}

.card.style-1 .num {
  color: #ffffff;
  font-family: "LeagueGothic-Regular";
  font-size: 1.4vw; // 28
}

.card.style-1 .num img {
  width: 2.8vw;
  border: 0;
  margin: 0;
}

.main-container {
  min-height: calc(100vh - 45px);
  background-image: url('/assets/img/new_year.jpg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 0 0;
}

.mt-55 {
  margin-top: 5rem;
}

.mt-6 {
  margin-top: 6rem;
}

.mt-22 {
  margin-top: 2rem;
}

.card.style-2 {
  border: 0;
}

.card.style-3 {
  border: 0;
  display: flex;
  align-items: center;
  flex-flow: row;
  padding: 0.75vw 0; // 14 0
  border-bottom: 1px dashed rgba(0, 0, 0, .1);
  border-radius: 0;
  justify-content: space-between;
}

.card.style-3>span {
  /* padding-right: .5rem; */
}

.card.style-3 .name {
  font-family: "LeagueGothic-Regular";
  font-size: 1.4vw; // 28px

  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 15ch;

  margin-right: 1vw; // 20
}

.card.style-3 .circle-num {
  margin-right: 0.7vw; // 12
}

.circle-num {
  font-family: "LeagueGothic-Regular";
  background-color: #575757;
  width: 1.55vw; //32px
  height: 1.55vw; //32px
  border-radius: 1.55vw; //32px

  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 1.2vw; // 24


  position: relative;

  p {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    line-height: 1;
  }
}

.header-card {
  padding-bottom: 0.8vw; // 14

  display: flex;
  flex-direction: column;

  .heading {
    font-family: "Poppins-SemiBold";
    font-size: 1.3vw; // 26px
    position: relative;
    margin-bottom: 0.3vw; // 6px
  }

  .date {
    font-size: 1.1vw; // 22px
  }
}

.cards-container {
  display: flex;
}

.cards-container .card {
  margin-right: 2rem;
}


// .bg-1 {
//   background: transparent linear-gradient(259deg, rgba(30, 45, 91, 1) 0%, rgba(30, 45, 91, 0.7) 100%) 0% 0% no-repeat padding-box !important;
// }

// .bg-2 {
//   background: transparent linear-gradient(259deg, rgba(30, 45, 91, 0.8) 0%, rgba(30, 45, 91, 0.6) 100%) 0% 0% no-repeat padding-box !important;
// }

// .bg-3 {
//   background: transparent linear-gradient(262deg, rgba(251, 181, 38, 1) 0%, rgba(251, 181, 38, 0.7) 100%) 0% 0% no-repeat padding-box !important;
// }

// .bg-4 {
//   background: transparent linear-gradient(265deg, rgba(251, 181, 38, 0.9) 0%, rgba(251, 181, 38, 0.6) 100%) 0% 0% no-repeat padding-box !important;
// }

// .bg-5 {
//   background: transparent linear-gradient(265deg, rgba(251, 181, 38, 0.7) 0%, rgba(251, 181, 38, 0.6) 100%) 0% 0% no-repeat padding-box !important;
// }

// .bg-6 {
//   background: transparent linear-gradient(265deg, rgba(109, 150, 169, 1) 0%, rgba(109, 150, 169, 0.8) 100%) 0% 0% no-repeat padding-box !important;
// }

// .bg-7 {
//   background: transparent linear-gradient(265deg, rgba(109, 150, 169, 0.9) 0%, rgba(109, 150, 169, 0.7) 100%) 0% 0% no-repeat padding-box !important;
// }

// .bg-8 {
//   background: transparent linear-gradient(265deg, rgba(109, 150, 169, 0.8) 0%, rgba(109, 150, 169, 0.6) 100%) 0% 0% no-repeat padding-box !important;
// }

// .bg-9 {
//   background: transparent linear-gradient(266deg, rgba(74, 113, 118, 1) 0%, rgba(74, 113, 118, 0.8) 100%) 0% 0% no-repeat padding-box !important;
// }

// .bg-10 {
//   background: transparent linear-gradient(266deg, rgba(74, 113, 118, 0.9) 0%, rgba(74, 113, 118, 0.7) 100%) 0% 0% no-repeat padding-box !important;
// }

// .bg-11 {
//   background: transparent linear-gradient(266deg, rgba(74, 113, 118, 0.8) 0%, rgba(74, 113, 118, 0.6) 100%) 0% 0% no-repeat padding-box !important;
// }

// .bg-12 {
//   background: transparent linear-gradient(266deg, rgba(180, 233, 239, 1) 0%, rgba(120, 233, 239, 0.9) 100%) 0% 0% no-repeat padding-box !important;
// }

// .bg-13 {
//   background: transparent linear-gradient(266deg, rgba(180, 233, 239, 0.9) 0%, rgba(120, 233, 239, 0.8) 100%) 0% 0% no-repeat padding-box !important;
// }

// .bg-14 {
//   background: transparent linear-gradient(266deg, rgba(180, 233, 239, 0.8) 0%, rgba(120, 233, 239, 0.7) 100%) 0% 0% no-repeat padding-box !important;
// }


// .bg-15 {
//   background: transparent linear-gradient(266deg, rgba(67, 65, 66, 1) 0%, rgba(67, 65, 66, 0.9) 100%) 0% 0% no-repeat padding-box !important;
// }

// .bg-16 {
//   background: transparent linear-gradient(266deg, rgba(67, 65, 66, 0.9) 0%, rgba(67, 65, 66, 0.8) 100%) 0% 0% no-repeat padding-box !important;
// }

// .bg-17 {
//   background: transparent linear-gradient(266deg, rgba(67, 65, 66, 0.8) 0%, rgba(67, 65, 66, 0.7) 100%) 0% 0% no-repeat padding-box !important;
// }

// .bg-18 {
//   background: transparent linear-gradient(266deg, rgba(145, 148, 150, 1) 0%, rgba(145, 148, 150, 0.9) 100%) 0% 0% no-repeat padding-box !important;
// }

// .bg-19 {
//   background: transparent linear-gradient(266deg, rgba(145, 148, 150, 0.9) 0%, rgba(145, 148, 150, 0.8) 100%) 0% 0% no-repeat padding-box !important;
// }

// .bg-20 {
//   background: transparent linear-gradient(266deg, rgba(145, 148, 150, 0.8) 0%, rgba(145, 148, 150, 0.7) 100%) 0% 0% no-repeat padding-box !important;
// }

.bg-num-day-1 {
  background-color: #7762EF;
}

.bg-num-day-2 {
  background-color: #705FD0;
}

.bg-num-day-3 {
  background-color: #6B5DB6;
}

.bg-num-day-4 {
  background-color: #665CA2;
}

.bg-num-day-5 {
  background-color: #615A89;
}

.bg-num-day-6 {
  background-color: #5D5973;
}

.bg-num-day-7 {
  background-color: #504C65;
}

.bg-num-day-8 {
  background-color: #484269;
}

.bg-num-day-9 {
  background-color: #575757;
}

.bg-num-day-10 {
  background-color: #575757;
}

.bg-num-week-1 {
  background-color: #FF9B27;
}

.bg-num-week-2 {
  background-color: #DD8D30;
}

.bg-num-week-3 {
  background-color: #C48337;
}

.bg-num-week-4 {
  background-color: #A9783F;
}

.bg-num-week-5 {
  background-color: #8F6D46;
}

.bg-num-week-6 {
  background-color: #75634E;
}

.bg-num-week-7 {
  background-color: #5F5345;
}

.bg-num-week-8 {
  background-color: #453F39;
}

.bg-num-week-9 {
  background-color: #575757;
}

.bg-num-week-10 {
  background-color: #575757;
}

.footer {
  background-color: rgb(242, 242, 242);
  color: rgb(139, 139, 139);
  padding: 12px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1vw; // 28px

  p {
    margin: 0;

    a {
      color: rgb(139, 139, 139);

      &:hover {
        color: #705FD0;
        text-decoration: none;
      }
    }
  }
}

// responsive

@media only screen and (max-width: 1300px) {

  .rect,
  .semi-rect,
  .small-rect {
    height: 8px;
    margin-bottom: 5px;
    border-radius: 5px;
    min-width: 50px;
  }

  .pl-vw {
    padding-left: 27px;
  }

  .mb-vw {
    margin-bottom: 10px;
  }

  .mr-vw {
    margin-right: 10px !important;
  }

  .pr-49 {
    padding-right: 29px;
  }

  .padder {
    // padding: 97px 34px 29px 63px;
    padding: 210px 34px 29px 63px;
  }

  .heading-3 {
    font-size: 16px;
  }

  .card.style-1 .num img {
    width: 28px;
    border: 0;
    margin: 0;


  }

  .active-style {
    padding: 7px 11px;
    border-radius: 8px;

    .heading {
      font-size: 16px;
    }

    .num {
      font-size: 36px;
    }
  }

  .active-splitter {
    margin: 15px 0;
  }

  .challenges {
    .challenge {
      img {
        margin-right: 5px;
        height: 22px;
      }

      .square {
        margin-right: 5px;
        height: 22px;
        width: 22px;
      }

      span {
        left: -5px;
        width: 13px;
        height: 13px;
        border-radius: 13px;
        font-size: 9px;
        line-height: 1;
      }
    }

    .extra {
      width: 22px;
      height: 22px;
      border-radius: 22px;
      font-size: 12px;
    }
  }

  .card.style-1 {
    padding: 6px 10px;
    border-radius: 8px;
    margin-bottom: 16px;
  }

  .name-size-1 {
    font-size: 24px;
  }

  .name-size-2 {
    font-size: 22px;
    ;
  }

  .name-size-3 {
    font-size: 16px;
  }

  .card.style-1 .info .desc {
    font-size: 12.2px;
  }

  .bg-2 {
    .info {
      .desc {
        font-size: 11px !important;
      }
    }
  }

  .score-size-1 {
    font-size: 24px;
  }

  .score-size-2 {
    font-size: 16px;
  }

  .card.style-1 .num {
    font-size: 16px;
  }

  .card.style-3 {
    padding: 12.9px 0;
  }

  .card.style-3 .name {
    font-size: 16px;
    margin-right: 10px;
  }

  .footer p {
    font-size: 14px;
  }

  .card.style-3 .circle-num {
    margin-right: 7px;
  }

  .circle-num {
    width: 17.5px;
    height: 17.5px;
    border-radius: 17.5px;
    font-size: 14px;
  }

  .header-card {
    padding-bottom: 8px;

    .heading {
      font-size: 15px;
      margin-bottom: 3px;
    }

    .date {
      font-size: 13px;
    }
  }
}


@media only screen and (max-width: 767px) {
  .padder {
    // padding: 10vw 40px 40px 40px;
    padding: 22vw 40px 40px 40px;
  }

  .pr-49 {
    padding-right: 0;
  }

  .col-md-8 {
    padding: 0;
    margin-top: 30px;
  }

  .col-md-6 {
    padding: 0 15px;
    margin-bottom: 20px;
  }

  .active-style {
    min-width: 100%;
  }
}


@keyframes loading {
  0% {
    opacity: 0.8;
  }

  50% {
    opacity: 0.2;
  }

  100% {
    opacity: 0.8;
  }
}
